export default [
  { _id: 0, ids: { 2022: 0, 2023: 0 }, absprung: 0, f1: 0, f2: 0, ausfuehrung: 0, beschreibung: 'ungültiger Sprung', lad: 0, bad: 0, name: '', punkte: 0, bonus: 0, fav: true },
  { _id: 1, ids: { 2022: 1, 2023: 1 }, absprung: 0, f1: 0, f2: 0, ausfuehrung: 0, beschreibung: 'Sprunggrätsche', lad: 0, bad: 0, name: '', punkte: 0.5, bonus: 0 },
  { _id: 2, ids: { 2022: 2, 2023: 2 }, absprung: 0, f1: 0, f2: 0, ausfuehrung: 0, beschreibung: 'Sprunghocke', lad: 0, bad: 0, name: '', punkte: 0.5, bonus: 0 },
  { _id: 3, ids: { 2022: 3, 2023: 3 }, absprung: 0, f1: 0, f2: 0, ausfuehrung: 0, beschreibung: 'Sprungbücke', lad: 0, bad: 0, name: '', punkte: 1.1, bonus: 0 },
  { _id: 4, ids: { 2022: 4, 2023: 4 }, absprung: 0, f1: 0, f2: 0, ausfuehrung: 0, beschreibung: 'Hecht', lad: 0, bad: 0, name: '', punkte: 1.6, bonus: 0 },
  { _id: 101, ids: { 2022: 101, 2023: 201 }, absprung: 0, f1: 0, f2: 0, ausfuehrung: 0, beschreibung: 'Überschlag vw.', lad: 0, bad: 0, name: '', punkte: 1.6, bonus: 0, fav: true },
  { _id: 102, ids: { 2022: 102, 2023: 202 }, absprung: 0, f1: 0, f2: 0.5, ausfuehrung: 0, beschreibung: 'Überschl. vw. mit 1/2 Drehung', lad: 0.5, bad: 0, name: '', punkte: 1.8, bonus: 0.2 },
  { _id: 103, ids: { 2022: 103, 2023: 203 }, absprung: 0, f1: 0, f2: 1, ausfuehrung: 0, beschreibung: 'Überschl. vw. mit 1/1 Drehung', lad: 1, bad: 0, name: '', punkte: 2, bonus: 0.4 },
  { _id: 104, ids: { 2022: 104, 2023: 204 }, absprung: 0, f1: 0, f2: 1.5, ausfuehrung: 0, beschreibung: 'Überschl. vw. mit 3/2 Drehungen', lad: 1.5, bad: 0, name: '', punkte: 2.2, bonus: 0.4 },
  { _id: 105, ids: { 2022: 105, 2023: 205 }, absprung: 0, f1: 0, f2: 2, ausfuehrung: 0, beschreibung: 'Überschl. vw. mit 2/1 Drehungen', lad: 2, bad: 0, name: '', punkte: 2.4, bonus: 0.4 },
  { _id: 106, ids: { 2022: 106, 2023: 206 }, absprung: 0, f1: 0, f2: 2.5, ausfuehrung: 0, beschreibung: 'Überschl. vw. mit 5/2 Drehungen', lad: 2.5, bad: 0, name: 'Tsygankov', punkte: 2.6, bonus: 0.4 },
  { _id: 107, ids: { 2022: 107, 2023: 207 }, absprung: 0, f1: 0, f2: 0, ausfuehrung: 1, beschreibung: 'Überschl. vw. u. Salto vw. geh.', lad: 0, bad: 1, name: '', punkte: 2.4, bonus: 0.4, fav: true },
  { _id: 108, ids: { 2022: 108, 2023: 208 }, absprung: 0, f1: 0, f2: 0.5, ausfuehrung: 1, beschreibung: 'Überschl. vw. u. Salto vw. geh. mit 1/2 Dr.', lad: 0.5, bad: 1, name: '', punkte: 2.8, bonus: 0.6 },
  { _id: 109, ids: { 2022: 109, 2023: 101 }, absprung: 0, f1: 0, f2: 1, ausfuehrung: 1, beschreibung: 'Überschl. vw. u. Salto vw. geh. mit 1/1 Dr.', lad: 1, bad: 1, name: '', punkte: 3.2, bonus: 0.8 },
  { _id: 110, ids: { 2022: 110, 2023: 102 }, absprung: 0, f1: 0, f2: 1.5, ausfuehrung: 1, beschreibung: 'Überschl. vw. u. Salto vw. geh. mit 3/2 Dr.', lad: 1.5, bad: 1, name: 'Kroll', punkte: 3.6, bonus: 0.8 },
  { _id: 111, ids: { 2022: 111, 2023: 103 }, absprung: 0, f1: 0, f2: 2, ausfuehrung: 1, beschreibung: 'Überschl. vw. u. Salto vw. geh. mit 2/1 Dr.', lad: 2, bad: 1, name: 'Canhas', punkte: 4, bonus: 0.8 },
  { _id: 112, ids: { 2022: 112, 2023: 213 }, absprung: 0, f1: 0, f2: 0, ausfuehrung: 2, beschreibung: 'Überschl. vw. u. Salto vw. geb.', lad: 0, bad: 1, name: '', punkte: 2.8, bonus: 0.4 },
  { _id: 113, ids: { 2022: 113, 2023: 214 }, absprung: 0, f1: 0, f2: 0.5, ausfuehrung: 2, beschreibung: 'Überschl. vw. u. Salto vw. geb. mit 1/2 Dr.', lad: 0.5, bad: 1, name: '', punkte: 3.2, bonus: 0.6 },
  { _id: 114, ids: { 2022: 114, 2023: 107 }, absprung: 0, f1: 0, f2: 1, ausfuehrung: 2, beschreibung: 'Überschl. vw. u. Salto vw. geb. mit 1/1 Dr.', lad: 1, bad: 1, name: '', punkte: 3.6, bonus: 0.8 },
  { _id: 115, ids: { 2022: 115, 2023: 108 }, absprung: 0, f1: 0, f2: 1.5, ausfuehrung: 2, beschreibung: 'Überschl. vw. u. Salto vw. geb. mit 3/2 Dr.', lad: 1.5, bad: 1, name: '', punkte: 4, bonus: 0.8 },
  { _id: 116, ids: { 2022: 116, 2023: -1 }, absprung: 0, f1: 0, f2: 1, ausfuehrung: 1, beschreibung: 'Überschl. vw. mit 1/1 Dr. u. Salto vw. geh.', lad: 1, bad: 1, name: 'Behrend', punkte: 4, bonus: 0.8 },
  { _id: 117, ids: { 2022: 117, 2023: -1 }, absprung: 0, f1: 0, f2: 1, ausfuehrung: 2, beschreibung: 'Überschl. vw. mit 1/1 Dr. u. Salto vw. geb. ', lad: 1, bad: 1, name: 'Rehm', punkte: 4.4, bonus: 0.8 },
  { _id: 118, ids: { 2022: 118, 2023: -1 }, absprung: 0, f1: 0, f2: 1.5, ausfuehrung: 2, beschreibung: 'Überschl. vw. mit 1/1 Dr. u. Salto vw. geb. mit 1/2 Dr.', lad: 1.5, bad: 1, name: 'Arican', punkte: 4.8, bonus: 0.8 },
  { _id: 171, ids: { 2022: 171, 2023: 219 }, absprung: 0, f1: 0, f2: 0, ausfuehrung: 3, beschreibung: 'Überschl. vw. u. Salto vw. gestr.', lad: 0, bad: 1, name: '', punkte: 3.6, bonus: 0.4 },
  { _id: 172, ids: { 2022: 172, 2023: 220 }, absprung: 0, f1: 0, f2: 0.5, ausfuehrung: 3, beschreibung: 'Überschl. vw. u. Salto vw. gestr. mit 1/2 Dr.', lad: 0.5, bad: 1, name: '', punkte: 4, bonus: 0.6 },
  { _id: 173, ids: { 2022: 173, 2023: 113 }, absprung: 0, f1: 0, f2: 1, ausfuehrung: 3, beschreibung: 'Überschl. vw. u. Salto vw. gestr. mit 1/1 Dr.', lad: 1, bad: 1, name: '', punkte: 4.4, bonus: 0.8 },
  { _id: 174, ids: { 2022: 174, 2023: 114 }, absprung: 0, f1: 0, f2: 1.5, ausfuehrung: 3, beschreibung: 'Überschl. vw. u. Salto vw. gestr. mit 3/2 Dr.', lad: 1.5, bad: 1, name: 'Lou Yun', punkte: 4.8, bonus: 0.8 },
  { _id: 175, ids: { 2022: 175, 2023: 115 }, absprung: 0, f1: 0, f2: 2, ausfuehrung: 3, beschreibung: 'Überschl. vw. u. Salto vw. gestr. mit 2/1 Dr.', lad: 2, bad: 1, name: '', punkte: 5.2, bonus: 0.8 },
  { _id: 176, ids: { 2022: 176, 2023: 116 }, absprung: 0, f1: 0, f2: 2.5, ausfuehrung: 3, beschreibung: 'Überschl. vw. u. Salto vw. gestr. mit 5/2 Dr.', lad: 2.5, bad: 1, name: 'Yeo 2', punkte: 5.6, bonus: 0.8 },
  { _id: 177, ids: { 2022: 177, 2023: 117 }, absprung: 0, f1: 0, f2: 3, ausfuehrung: 3, beschreibung: 'Überschl. vw. u. Salto vw. gestr. mit 3/1 Dr. ', lad: 3, bad: 1, name: 'Yang Hak Seon', punkte: 6, bonus: 0.8 },
  { _id: 178, ids: { 2022: 178, 2023: 225 }, absprung: 0, f1: 0, f2: 0, ausfuehrung: 1, beschreibung: 'Überschl. vw. u. Doppelsalto vw. geh.', lad: 0, bad: 2, name: 'Roche', punkte: 5.2, bonus: 0.4 },
  { _id: 179, ids: { 2022: 179, 2023: 226 }, absprung: 0, f1: 0, f2: 0.5, ausfuehrung: 1, beschreibung: 'Roche mit 1/2 Dr.', lad: 0.5, bad: 2, name: 'Dragulescu', punkte: 5.6, bonus: 0.6 },
  { _id: 190, ids: { 2022: 190, 2023: 228 }, absprung: 0, f1: 0, f2: 0.5, ausfuehrung: 1, beschreibung: 'Überschl. vw. u. Salto vw. geh. m. 1/2 Dr. u. Salto rw.', lad: 0.5, bad: 2, name: 'Zimmermann', punkte: 5.6, bonus: 0.6 },
  { _id: 191, ids: { 2022: 191, 2023: 231 }, absprung: 0, f1: 0, f2: 0, ausfuehrung: 2, beschreibung: 'Überschl. vw. u. Doppelsalto vw. geb.', lad: 0, bad: 2, name: 'Blanik', punkte: 5.6, bonus: 0.4 },
  { _id: 192, ids: { 2022: 192, 2023: 232 }, absprung: 0, f1: 0, f2: 0.5, ausfuehrung: 2, beschreibung: 'Dragulescu geb.', lad: 0.5, bad: 2, name: 'Ri Se Gwang 2', punkte: 6, bonus: 0.6 },
  { _id: 220, ids: { 2022: 220, 2023: 301 }, absprung: 0, f1: 0.25, f2: 0.25, ausfuehrung: 0, beschreibung: 'Überschl. sw. mit 1/4 Dr.', lad: 0.25, bad: 0, name: '', punkte: 1.6, bonus: 0 },
  { _id: 221, ids: { 2022: 221, 2023: 302 }, absprung: 0, f1: 0.25, f2: 0.75, ausfuehrung: 0, beschreibung: 'Überschl. sw. mit 3/4 Dr.', lad: 0.75, bad: 0, name: '', punkte: 1.8, bonus: 0.2 },
  { _id: 222, ids: { 2022: 222, 2023: 303 }, absprung: 0, f1: 0.25, f2: 1.25, ausfuehrung: 0, beschreibung: 'Überschl. sw. mit 5/4 Dr.', lad: 1.25, bad: 0, name: '', punkte: 2, bonus: 0.4 },
  { _id: 223, ids: { 2022: 223, 2023: -1 }, absprung: 0, f1: 0.25, f2: 0.25, ausfuehrung: 1, beschreibung: 'Überschl. sw. mit 1/4 Dr. u. Salto vw. geh.', lad: 0.25, bad: 1, name: '', punkte: 2.4, bonus: 0.4 },
  { _id: 224, ids: { 2022: 224, 2023: -1 }, absprung: 0, f1: 0.25, f2: 0.25, ausfuehrung: 2, beschreibung: 'Überschl. sw. mit 1/4 Dr. u. Salto vw. geb.', lad: 0.25, bad: 1, name: '', punkte: 2.8, bonus: 0.4 },
  { _id: 225, ids: { 2022: 225, 2023: 307 }, absprung: 0, f1: 0.25, f2: 0.25, ausfuehrung: 1, beschreibung: 'Tsukahara geh.', lad: 0.25, bad: 1, name: '', punkte: 2.2, bonus: 0.4, fav: true },
  { _id: 226, ids: { 2022: 226, 2023: 308 }, absprung: 0, f1: 0.25, f2: 0.25, ausfuehrung: 2, beschreibung: 'Tsukahara geb.', lad: 0.25, bad: 1, name: '', punkte: 2.4, bonus: 0.4, fav: true },
  { _id: 227, ids: { 2022: 227, 2023: 309 }, absprung: 0, f1: 0.25, f2: 0.75, ausfuehrung: 1, beschreibung: 'Tsukahara geh. mit 1/2 Dr.', lad: 0.75, bad: 1, name: '', punkte: 2.4, bonus: 0.6 },
  { _id: 228, ids: { 2022: 228, 2023: 310 }, absprung: 0, f1: 0.25, f2: 1.25, ausfuehrung: 1, beschreibung: 'Tsukahara geh. mit 1/1 Dr.', lad: 1.25, bad: 1, name: 'Kasamatsu', punkte: 2.8, bonus: 0.8 },
  { _id: 229, ids: { 2022: 229, 2023: 119 }, absprung: 0, f1: 0.25, f2: 1.75, ausfuehrung: 1, beschreibung: 'Tsukahara geh. mit 3/2 Dr.', lad: 1.75, bad: 1, name: '', punkte: 3.2, bonus: 0.8 },
  { _id: 230, ids: { 2022: 230, 2023: 120 }, absprung: 0, f1: 0.25, f2: 2.25, ausfuehrung: 1, beschreibung: 'Tsukahara geh. mit 2/1 Dr.', lad: 2.25, bad: 1, name: 'Barbier', punkte: 3.6, bonus: 0.8 },
  { _id: 270, ids: { 2022: 270, 2023: 311 }, absprung: 0, f1: 0.25, f2: 0.25, ausfuehrung: 3, beschreibung: 'Tsukahara gestr.', lad: 0.25, bad: 1, name: '', punkte: 3.2, bonus: 0.4, fav: true },
  { _id: 271, ids: { 2022: 271, 2023: 312 }, absprung: 0, f1: 0.25, f2: 0.75, ausfuehrung: 3, beschreibung: 'Tsukahara gestr. mit 1/2 Dr.', lad: 0.75, bad: 1, name: '', punkte: 3.6, bonus: 0.6 },
  { _id: 272, ids: { 2022: 272, 2023: 313 }, absprung: 0, f1: 0.25, f2: 1.25, ausfuehrung: 3, beschreibung: 'Tsukahara gestr. mit 1/1 Dr.', lad: 1.25, bad: 1, name: '', punkte: 4, bonus: 0.8 },
  { _id: 273, ids: { 2022: 273, 2023: 125 }, absprung: 0, f1: 0.25, f2: 1.75, ausfuehrung: 3, beschreibung: 'Tsukahara gestr. mit 3/2 Dr.', lad: 1.75, bad: 1, name: '', punkte: 4.4, bonus: 0.8 },
  { _id: 274, ids: { 2022: 274, 2023: 126 }, absprung: 0, f1: 0.25, f2: 2.25, ausfuehrung: 3, beschreibung: 'Tsukahara gestr. mit 2/1 Dr.', lad: 2.25, bad: 1, name: 'Akopian', punkte: 4.8, bonus: 0.8 },
  { _id: 275, ids: { 2022: 275, 2023: 131 }, absprung: 0, f1: 0.25, f2: 2.75, ausfuehrung: 3, beschreibung: 'Tsukahara gestr. mit 5/2 Dr.', lad: 2.75, bad: 1, name: 'Driggs', punkte: 5.2, bonus: 0.8 },
  { _id: 276, ids: { 2022: 276, 2023: 132 }, absprung: 0, f1: 0.25, f2: 3.25, ausfuehrung: 3, beschreibung: 'Tsukahara gestr. mit 3/1 Dr.', lad: 3.25, bad: 1, name: 'Lopez', punkte: 5.6, bonus: 0.8 },
  { _id: 277, ids: { 2022: 277, 2023: 133 }, absprung: 0, f1: 0.25, f2: 3.75, ausfuehrung: 3, beschreibung: 'Tsukahara gestr. mit 7/2 Dr.', lad: 3.75, bad: 1, name: 'Yonekura', punkte: 6, bonus: 0.8 },
  { _id: 285, ids: { 2022: 285, 2023: 319 }, absprung: 0, f1: 0.25, f2: 0.25, ausfuehrung: 1, beschreibung: 'Tsukahara geh. mit Salto rw. geh.', lad: 0.25, bad: 2, name: 'Yeo', punkte: 5.2, bonus: 0.4 },
  { _id: 286, ids: { 2022: 286, 2023: 320 }, absprung: 0, f1: 0.25, f2: 0.25, ausfuehrung: 2, beschreibung: 'Tsukahara geb. mit Salto rw. geb.', lad: 0.25, bad: 2, name: 'Lu Yu Fu', punkte: 5.6, bonus: 0.4 },
  { _id: 287, ids: { 2022: 287, 2023: 321 }, absprung: 0, f1: 0.25, f2: 1.25, ausfuehrung: 1, beschreibung: 'Tsukahara geh. mit Salto rw. geh. mit 1/1 Dr.', lad: 1.25, bad: 2, name: 'Ri Se Gwang', punkte: 6, bonus: 0.8 },
  { _id: 335, ids: { 2022: 335, 2023: 401 }, absprung: 1, f1: 0, f2: 0, ausfuehrung: 0, beschreibung: 'Rondat und Überschl. rw.', lad: 0, bad: 0, name: '', punkte: 1.6, bonus: 0 },
  { _id: 336, ids: { 2022: 336, 2023: 402 }, absprung: 1, f1: 0, f2: 0.5, ausfuehrung: 0, beschreibung: 'Rondat und Überschl. rw. mit 1/2 Dr.', lad: 0.5, bad: 0, name: '', punkte: 1.8, bonus: 0.2 },
  { _id: 337, ids: { 2022: 337, 2023: 403 }, absprung: 1, f1: 0, f2: 1, ausfuehrung: 0, beschreibung: 'Rondat und Überschl. rw. mit 1/1 Dr.', lad: 1, bad: 0, name: '', punkte: 2, bonus: 0.4 },
  { _id: 338, ids: { 2022: 338, 2023: 407 }, absprung: 1, f1: 0, f2: 0, ausfuehrung: 1, beschreibung: 'Rondat und Überschl. rw. u. Salto rw. geh.', lad: 0, bad: 1, name: 'Yurchenko', punkte: 2.2, bonus: 0.4 },
  { _id: 339, ids: { 2022: 339, 2023: 408 }, absprung: 1, f1: 0, f2: 0.5, ausfuehrung: 0, beschreibung: 'Yurchenko geh. mit 1/2 Dr.', lad: 0.5, bad: 1, name: '', punkte: 2.4, bonus: 0.6 },
  { _id: 340, ids: { 2022: 340, 2023: 409 }, absprung: 1, f1: 0, f2: 1, ausfuehrung: 0, beschreibung: 'Yurchenko geh. mit 1/1 Dr.', lad: 1, bad: 1, name: '', punkte: 2.8, bonus: 0.8 },
  { _id: 341, ids: { 2022: 341, 2023: 410 }, absprung: 1, f1: 0, f2: 1.5, ausfuehrung: 0, beschreibung: 'Yurchenko geh. mit 3/2 Dr.', lad: 1.5, bad: 1, name: '', punkte: 3.2, bonus: 0.8 },
  { _id: 342, ids: { 2022: 342, 2023: 411 }, absprung: 1, f1: 0, f2: 2, ausfuehrung: 0, beschreibung: 'Yurchenko geh. mit 2/1 Dr.', lad: 2, bad: 1, name: '', punkte: 3.6, bonus: 0.8 },
  { _id: 343, ids: { 2022: 343, 2023: 413 }, absprung: 1, f1: 0, f2: 0, ausfuehrung: 2, beschreibung: 'Yurchenko geb.', lad: 0, bad: 1, name: '', punkte: 2.4, bonus: 0.4 },
  { _id: 370, ids: { 2022: 370, 2023: 414 }, absprung: 1, f1: 0, f2: 0, ausfuehrung: 3, beschreibung: 'Yurchenko gestr.', lad: 0, bad: 1, name: '', punkte: 3.2, bonus: 0.4 },
  { _id: 371, ids: { 2022: 371, 2023: 415 }, absprung: 1, f1: 0, f2: 0.5, ausfuehrung: 3, beschreibung: 'Yurchenko gestr. mit 1/2 Dr.', lad: 0.5, bad: 1, name: '', punkte: 3.6, bonus: 0.6 },
  { _id: 372, ids: { 2022: 372, 2023: 416 }, absprung: 1, f1: 0, f2: 1, ausfuehrung: 3, beschreibung: 'Yurchenko gestr. mit 1/1 Dr.', lad: 1, bad: 1, name: '', punkte: 4, bonus: 0.8 },
  { _id: 373, ids: { 2022: 373, 2023: 417 }, absprung: 1, f1: 0, f2: 1.5, ausfuehrung: 3, beschreibung: 'Yurchenko gestr. mit 3/2 Dr.', lad: 1.5, bad: 1, name: '', punkte: 4.4, bonus: 0.8 },
  { _id: 374, ids: { 2022: 374, 2023: 418 }, absprung: 1, f1: 0, f2: 2, ausfuehrung: 3, beschreibung: 'Yurchenko gestr. mit 2/1 Dr.', lad: 2, bad: 1, name: '', punkte: 4.8, bonus: 0.8 },
  { _id: 375, ids: { 2022: 375, 2023: 419 }, absprung: 1, f1: 0, f2: 2.5, ausfuehrung: 3, beschreibung: 'Yurchenko gestr. mit 5/2 Dr.', lad: 2.5, bad: 1, name: 'Shewfelt', punkte: 5.2, bonus: 0.8 },
  { _id: 376, ids: { 2022: 376, 2023: 420 }, absprung: 1, f1: 0, f2: 3, ausfuehrung: 3, beschreibung: 'Yurchenko gestr. mit 3/1 Dr.', lad: 3, bad: 1, name: 'Shirai / Hee Hoon Kim', punkte: 5.6, bonus: 0.8 },
  { _id: 385, ids: { 2022: 385, 2023: 425 }, absprung: 1, f1: 0, f2: 0, ausfuehrung: 1, beschreibung: 'Yurchenko und Salto rw. geh.', lad: 0, bad: 2, name: 'Melissanidis', punkte: 5.2, bonus: 0.4 },
  { _id: 386, ids: { 2022: 386, 2023: 426 }, absprung: 1, f1: 0, f2: 0, ausfuehrung: 2, beschreibung: 'Melissanidis geb.', lad: 0, bad: 2, name: 'Wei', punkte: 5.6, bonus: 0.4 },
  { _id: 450, ids: { 2022: 450, 2023: 431 }, absprung: 1, f1: 0.5, f2: 0, ausfuehrung: 0, beschreibung: 'Rondat, 1/2 Drehung u. Überschl. vw.', lad: 0.5, bad: 0, name: '', punkte: 1.8, bonus: 0.2 },
  { _id: 451, ids: { 2022: 451, 2023: 432 }, absprung: 1, f1: 0.5, f2: 0.5, ausfuehrung: 0, beschreibung: 'Rondat, 1/2 Drehung u. Überschl. vw. mit 1/2 Dr.', lad: 1, bad: 0, name: '', punkte: 2, bonus: 0.4 },
  { _id: 452, ids: { 2022: 452, 2023: 433 }, absprung: 1, f1: 0.5, f2: 1, ausfuehrung: 0, beschreibung: 'Rondat, 1/2 Drehung u. Überschl. vw. mit 1/1 Dr.', lad: 1.5, bad: 0, name: '', punkte: 2.2, bonus: 0.4 },
  { _id: 453, ids: { 2022: 453, 2023: 434 }, absprung: 1, f1: 0.5, f2: 0, ausfuehrung: 1, beschreibung: 'Rondat, 1/2 Dr. u. Überschl. vw. u. Salto vw. geh.', lad: 0.5, bad: 1, name: '', punkte: 2.6, bonus: 0.6 },
  { _id: 454, ids: { 2022: 454, 2023: 435 }, absprung: 1, f1: 0.5, f2: 0.5, ausfuehrung: 1, beschreibung: 'Rondat, 1/2 Dr. u. Überschl. vw. u. Salto vw. geh. m. 1/2 Dr.', lad: 1, bad: 1, name: '', punkte: 3, bonus: 0.8 },
  { _id: 455, ids: { 2022: 455, 2023: 437 }, absprung: 1, f1: 0.5, f2: 0, ausfuehrung: 2, beschreibung: 'Rondat, 1/2 Dr. u. Überschl. vw. u. Salto vw. geb.', lad: 0.5, bad: 1, name: '', punkte: 3, bonus: 0.6 },
  { _id: 456, ids: { 2022: 456, 2023: 438 }, absprung: 1, f1: 0.5, f2: 0.5, ausfuehrung: 2, beschreibung: 'Rondat, 1/2 Dr. u. Überschl. vw. u. Salto vw. geb. m. 1/2 Dr.', lad: 1, bad: 1, name: 'Nemov', punkte: 3.4, bonus: 0.8 },
  { _id: 471, ids: { 2022: 471, 2023: 439 }, absprung: 1, f1: 0.5, f2: 0, ausfuehrung: 3, beschreibung: 'Rondat, 1/2 Dr. u. Überschl. vw. u. Salto vw. gestr.', lad: 0.5, bad: 1, name: '', punkte: 3.8, bonus: 0.6 },
  { _id: 472, ids: { 2022: 472, 2023: 440 }, absprung: 1, f1: 0.5, f2: 0.5, ausfuehrung: 3, beschreibung: 'Rondat, 1/2 Dr. u. Überschl. vw. u. Salto vw. gestr. m. 1/2 Dr.', lad: 1, bad: 1, name: 'Hutcheon', punkte: 4.2, bonus: 0.8 },
  { _id: 473, ids: { 2022: 473, 2023: 441 }, absprung: 1, f1: 0.5, f2: 1, ausfuehrung: 3, beschreibung: 'Rondat, 1/2 Dr. u. Überschl. vw. u. Salto vw. gestr. m. 1/1 Dr.', lad: 1.5, bad: 1, name: '', punkte: 4.6, bonus: 0.8 },
  { _id: 474, ids: { 2022: 474, 2023: 442 }, absprung: 1, f1: 0.5, f2: 1.5, ausfuehrung: 3, beschreibung: 'Rondat, 1/2 Dr. u. Überschl. vw. u. Salto vw. gestr. m. 3/2 Dr.', lad: 2, bad: 1, name: '', punkte: 5, bonus: 0.8 },
  { _id: 475, ids: { 2022: 475, 2023: 443 }, absprung: 1, f1: 0.5, f2: 2, ausfuehrung: 3, beschreibung: 'Rondat, 1/2 Dr. u. Überschl. vw. u. Salto vw. gestr. m. 2/1 Dr.', lad: 2.5, bad: 1, name: '', punkte: 5.4, bonus: 0.8 },
  { _id: 476, ids: { 2022: 476, 2023: 444 }, absprung: 1, f1: 0.5, f2: 2.5, ausfuehrung: 3, beschreibung: 'Rondat, 1/2 Dr. u. Überschl. vw. u. Salto vw. gestr. m. 5/2 Dr.', lad: 3, bad: 1, name: 'Li Xiao Peng', punkte: 5.8, bonus: 0.8 },
  { _id: 478, ids: { 2022: 478, 2023: 449 }, absprung: 1, f1: 0.5, f2: 0, ausfuehrung: 1, beschreibung: 'Rondat, 1/2 Dr. u. Überschl. vw. u. Doppels. vw. geh.', lad: 0.5, bad: 2, name: '', punkte: 5.4, bonus: 0.6 },
  { _id: 560, ids: { 2022: 560, 2023: 455 }, absprung: 1, f1: 1, f2: 0, ausfuehrung: 0, beschreibung: 'Rondat, 1/1 Dr. zum Überschl. rw.', lad: 1, bad: 0, name: '', punkte: 2.2, bonus: 0.4 },
  { _id: 561, ids: { 2022: 561, 2023: 456 }, absprung: 1, f1: 1, f2: 0.5, ausfuehrung: 0, beschreibung: 'Rondat, 1/1 Dr. zum Überschl. rw. mit 1/2 Dr.', lad: 1.5, bad: 0, name: '', punkte: 2.4, bonus: 0.4 },
  { _id: 562, ids: { 2022: 562, 2023: 457 }, absprung: 1, f1: 1, f2: 1, ausfuehrung: 0, beschreibung: 'Rondat, 1/1 Dr. zum Überschl. rw. mit 1/1 Dr.', lad: 2, bad: 0, name: '', punkte: 2.6, bonus: 0.4 },
  { _id: 563, ids: { 2022: 563, 2023: 458 }, absprung: 1, f1: 1, f2: 0, ausfuehrung: 1, beschreibung: 'Rondat, 1/1 Dr. zum Überschl. rw. u. Salto rw. geh.', lad: 1, bad: 1, name: '', punkte: 2.8, bonus: 0.8 },
  { _id: 564, ids: { 2022: 564, 2023: 459 }, absprung: 1, f1: 1, f2: 0, ausfuehrung: 2, beschreibung: 'Rondat, 1/1 Dr. zum Überschl. rw. u. Salto rw. geb.', lad: 1, bad: 1, name: '', punkte: 3, bonus: 0.8 },
  { _id: 565, ids: { 2022: 565, 2023: 460 }, absprung: 1, f1: 1, f2: 0.5, ausfuehrung: 1, beschreibung: 'Rondat, 1/1 Dr. zum Überschl. rw. u. Salto rw. geh. m. 1/2 Dr.', lad: 1.5, bad: 1, name: '', punkte: 3, bonus: 0.8 },
  { _id: 566, ids: { 2022: 566, 2023: 461 }, absprung: 1, f1: 1, f2: 1, ausfuehrung: 1, beschreibung: 'Rondat, 1/1 Dr. zum Überschl. rw. u. Salto rw. geh. m. 1/1 Dr.', lad: 2, bad: 1, name: '', punkte: 3.4, bonus: 0.8 },
  { _id: 567, ids: { 2022: 567, 2023: 462 }, absprung: 1, f1: 1, f2: 1.5, ausfuehrung: 1, beschreibung: 'Rondat, 1/1 Dr. zum Überschl. rw. u. Salto rw. geh. m. 3/2 Dr.', lad: 2.5, bad: 1, name: '', punkte: 2.8, bonus: 0.8 },
  { _id: 570, ids: { 2022: 570, 2023: 467 }, absprung: 1, f1: 1, f2: 0, ausfuehrung: 3, beschreibung: 'Rondat, 1/1 Dr. zum Überschl. rw. u. Salto rw. gestr.', lad: 1, bad: 1, name: 'Scherbo', punkte: 3.8, bonus: 0.8 },
  { _id: 571, ids: { 2022: 571, 2023: 468 }, absprung: 1, f1: 1, f2: 0.5, ausfuehrung: 3, beschreibung: 'Scherbo mit 1/2 Dr.', lad: 1.5, bad: 1, name: '', punkte: 4.2, bonus: 0.8 },
  { _id: 572, ids: { 2022: 572, 2023: 469 }, absprung: 1, f1: 1, f2: 1, ausfuehrung: 3, beschreibung: 'Scherbo mit 1/1 Dr.', lad: 2, bad: 1, name: '', punkte: 4.6, bonus: 0.8 },
  { _id: 573, ids: { 2022: 573, 2023: 470 }, absprung: 1, f1: 1, f2: 1.5, ausfuehrung: 3, beschreibung: 'Scherbo mit 3/2 Dr.', lad: 2.5, bad: 1, name: '', punkte: 5, bonus: 0.8 },
  { _id: 574, ids: { 2022: 574, 2023: 471 }, absprung: 1, f1: 1, f2: 2, ausfuehrung: 3, beschreibung: 'Scherbo mit 2/1 Dr.', lad: 3, bad: 1, name: 'Shirai 3', punkte: 5.4, bonus: 0.8 },
  { _id: 575, ids: { 2022: 575, 2023: 472 }, absprung: 1, f1: 1, f2: 2.5, ausfuehrung: 3, beschreibung: 'Scherbo mit 5/2 Dr.', lad: 3.5, bad: 1, name: '', punkte: 5.8, bonus: 0.8 }
]
